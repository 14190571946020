<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient
            :memberId="memberId"
            :selectValue="selectValue"
            @change="userChange"
          />
          <TemplateTreeList
            :type="'check'"
            :list-data="listData"
            :selectValue="listSelect"
            @change="listChange"
          ></TemplateTreeList>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button class="formula-template-button"  block type="info" @click="submit" style="width:90px"
              >提交</van-button
            >
          </div>
          <!-- 计算结果 -->
          <!-- <div
            class="CalculationResultsBox"
            v-if="countText || countText !== ''"
          > -->
            <!-- <div class="CalculationResults"><span>计算结果</span></div> -->
            <!-- 具体标准 -->
            <!-- <div>
              <div class="CalculationDetail"><span></span>{{ countText }}</div>
            </div> -->
            <!--            <div>-->
            <!--              <div class="CalculationDetail"><span></span>具体标准：</div>-->
            <!--            </div>-->
          <!-- </div> -->
        </div>
      </div>
      <CalculationResults  v-if="show" :countText="countText" :countTextList="countTextList"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences
        :references-data="tableData.references"
      ></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        title="应激指数"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import { Toast } from "vant";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import CalculationResults from "@/components/CalculationResults";
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateTreeList from "@/components/Template3/TemplateTreeList";
export default {
  name: "medicalSearch",
  data() {
    return {
      compute:true,
      show:false,
      listSelect: [],
      listData: [
        {
          title: "深静脉血栓的临床症状和体征",
          tips:'下肢肿胀和深静脉触痛',
          tipsStatus:false,
          value: 3,
        },
        {
          title: "肺栓塞的可能性大于其他疾病",
          value: 3,
        },
        {
          title: "HR>100次/分",
          value: 1.5,
        },
        {
          title: "最近四周内有手术史和制动史",
          value: 1.5,
        },
        {
          title: "既往有深静脉血栓史或肺栓塞史",
          value: 1.5,
        },
        {
          title: "咳血",
          value: 1,
        },
        {
          title: "恶性肿瘤史（正在治疗或近6月内治疗过或姑息治疗）",
          value: 1,
        },
      ],
      formulaCode: "",
      shengao: "",
      yingjizhishu: "",
      title: "",
      memberId: "1111",
      selectValue: "",
      username: "",
      value: "",
      showPopover: false,
      showPicker: false,
      columns: ["低度临床可能", "中度度临床可能", "高度临床可能"],
      nianling: "",
      xingbie: null,
      tableData: {},
      countText: "",
      patientId: null,
      result: null,
      yingjizhishuzhi: "",
      tizhong: "",
      xueqingnanongdu: "",
    };
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateTreeList,
    CalculationResults
  },
  async created() {
    await this.test();
    await this.getTableData();
  },

  methods: {
    listChange(result) {
      this.listSelect = result;
      this.show=false
    },
    coumputTetxt() {
      this.show=true
      let fenshu = 0;
      let fenzhi = [];
      fenzhi = this.listSelect
        .map((item) => {
          const list = this.listData.filter((row) => row.title === item);
          return list && list.length > 0 ? list[0].value : null;
        })
        .filter((title) => title !== null);
      for (let i = 0; i < fenzhi.length; i++) {
        fenshu += fenzhi[i];
      }
      if (fenshu < 2) {
        this.countText =fenshu+'分 '+ "低度临床可能";
      } else if (fenshu > 2 && fenshu <= 6) {
        this.countText =fenshu+'分 '+ "中度临床可能";
      } else if (fenshu > 6) {
        this.countText = fenshu+'分 '+"高度临床可能";
      }
      this.result = this.countText;
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: "KKSIPXDU",
      };
      const res = await getFormula(data.channel, data.code);
      if (res.code === 0) {
        this.tableData = res.data;
        this.formulaCode = res.data.code;
        this.title = res.data.chineseName;
      }
      if (
        this.$router.currentRoute.query.location === "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId,
        };
        const res = await getFormulaHuiXian(data);
        if (res.code === 0) {
          this.listSelect = res.data.content.value
            .map((item) => {
              const list = this.listData.filter((row) => row.title === item);
              return list && list.length > 0 ? list[0].title : null;
            })
            .filter((title) => title !== null);
          if (res.data.patientId)
            this.selectValue = {
              id: res.data.patientId,
              name: res.data.patientName,
            };
          this.coumputTetxt();
        }
      }
    },
    async submit() {
      // if (!this.listSelect.length || this.listSelect.length === 0) {
      //   Toast.fail('请至少选择一项')
      //   return
      // }
      this.coumputTetxt();
      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          value: this.listSelect,
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          DisplayResults:this.countText
        },
      };
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
      } else {
        Toast.fail(res.message);
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u) {
      if (!u) {
        return;
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid =
        userAI.indexOf("Android") > -1 || userAI.indexOf("Linux") > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u);
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u;
      }
    },
    yingjiClick() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.yingjizhishu = value;
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    async onSubmit() {
      this.coumputTetxt();
      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: { value: this.shengao, unit: "cm" },
          tizhong: { value: this.tizhong, unit: "kg" },
          nianling: { value: this.nianling, unit: "岁" },
          xingbie: { value: this.xingbie == 1 ? "男" : "女" },
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {},
      };
      if (this.xingbie == 1) {
        data.result.nanxing = { value: this.result, unit: "kcal" };
      } else {
        data.result.nvxing = { value: this.result, unit: "kcal" };
      }
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
      } else {
        Toast.fail(res.message);
      }
    },
    userChange(data) {
      if (data.birthday && data.birthday !== "") {
        this.nianling = this.calculateAge(data.birthday);
      } else {
        this.nianling = "";
      }
      this.xingbie = data.sex;
      this.patientId = data.id;
      this.selectValue = data;
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription {
    margin-top: 10px;
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody {
    background-color: #fff;
    padding-bottom: 10px;
    .CalculationResultsBox {
      padding: 0 8px;
      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName {
      font-size: 16px;
      i {
        color: red;
      }
    }
    .information {
      font-size: 16px;
      margin-right: 4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
    }
    .vBotton {
      width: 60px;
    }
    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 20px;
    }
    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding: 0px 0px;
}
::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references {
    margin-bottom: 15px;
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>
